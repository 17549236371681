import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Button } from "rsuite";
import FacultyMentorPanel from "../components/FacultyMentorPanel";
import FacultyWPLPanel from "../components/FacultyWPLPanel";
import {useNavigate} from "react-router-dom";


const Home = () => {

  const navigate = useNavigate();

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between align-items-center mb-3 ">
        <h2 className="display-4" >Faculty</h2>
        <div>
          <Button onClick={() => navigate('/faculty-add')} >Add Mentor</Button>
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>Mentors</Tab>
          <Tab>WPL</Tab>
        </TabList>
        <TabPanel>
          <FacultyMentorPanel />
        </TabPanel>
        <TabPanel>
          <FacultyWPLPanel />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Home;
