import React from 'react';
import {Button, ButtonToolbar, Container, Content, FlexboxGrid, Footer, Form, Panel, Schema} from "rsuite";
import TextInput from "../components/TextInput";
import {toast} from "react-toastify";
import {PasswordResetRequest} from "../services/users.service";
import {useNavigate} from "react-router-dom";
import {handleErrorBlock} from "../utils/errorBlock";

const ForgetPassword = () => {

    const navigate = useNavigate()

    const [formValue, setFormValue] = React.useState({
        emailAddress: "",
    });
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    const model = Schema.Model({
        emailAddress: Schema.Types.StringType()
            .isEmail("Please enter a valid email address.")
            .isRequired("This Field is required."),
    });


    const requestPasswordReset = async() => {
        if (!formRef.current.check()) {
            toast.error('Error: Validation Failed')
        }else{
           try{
               const res = await PasswordResetRequest(formValue);
               toast.success('Reset Link Sent Successfully');
               console.log(res.data)
           }catch(e){
               handleErrorBlock(e, navigate)
           }
        }
    }

    return (
        <Container className="vh-100 d-flex flex-column justify-content-between ">
            <Content className=" d-flex flex-column justify-content-center">
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={8}>
                        <Panel header={<h3>Forgot Password</h3>} bordered>
                            <Form
                                ref={formRef}
                                fluid
                                onSubmit={requestPasswordReset}
                                onChange={setFormValue}
                                onCheck={setFormError}
                                formValue={formValue}
                                model={model}
                            >
                                <TextInput
                                    label="email address"
                                    type="email"
                                    name="emailAddress"
                                />
                                <ButtonToolbar>
                                    <Button type="submit" appearance="primary">
                                       Reset Password
                                    </Button>
                                </ButtonToolbar>
                            </Form>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <Footer>Footer</Footer>
        </Container>
    );
};

export default ForgetPassword;
