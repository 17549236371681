import Cookies from "js-cookie";

import {setCookies} from "./helper";
const JWT_COOKIE_NAME = "tag-auth-token";

export const saveJWTToken = (jwtToken) => {
    setCookies(JWT_COOKIE_NAME, jwtToken, { expires: 7 });
};

export const fetchJWTToken = () => {
    return Cookies.get(JWT_COOKIE_NAME);
};

export const removeJWTToken = () => {
    return fetchJWTToken() && Cookies.remove(JWT_COOKIE_NAME);
};

export const handle401 = (history, dispatch) => {
    // dispatch(setUserProfileData(""));
    removeJWTToken();
    history.push("/");
};