import React, { useRef, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Form,
  Input,
  Schema,
  SelectPicker,
} from "rsuite";
import TextInput from "../components/TextInput";
import { useNavigate } from "react-router-dom";
import TextEditorPage from "./TextEditorPage";
import { createEvent, verifyEventUniqueId } from "../services/events.service";
import { handleErrorBlock } from "../utils/errorBlock";
import { MdOutlineCancel, MdTaskAlt } from "react-icons/md";
import { toast } from "react-toastify";
import CropperModal from "../components/CropperModal";
import { file } from "@babel/types";
const inputWidth = {
  width: "clamp(100px, 40vw, 200px)",
};
import { IoCloseSharp } from "react-icons/io5";
import { ImageUploadUtil } from "../services/util.service";
import uploadToCloudinary from "../utils/uploadToCloudinary";

const AddNewsEvent = () => {
  const editorRef = useRef("");
  const [validId, setValidId] = useState(null);
  const [cropperModal, setCropperModal] = useState(false);
  const [image, setImage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imgVal, setImgVal] = useState(null);
  const [formValue, setFormValue] = React.useState({
    title: "",
    excerpt: "",
    content: "",
    file: "",
    uniqueID: "",
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    title: Schema.Types.StringType().isRequired("Name Cannot be empty"),
    excerpt: Schema.Types.StringType().isRequired("This Field Required"),
    content: Schema.Types.StringType().isRequired("This Field is required."),
  });

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!formRef.current.check() || !validId) {
      toast.error("Please Check all the fields");
    } else {
      // const data = new FormData();
      // for (let item in formValue) {
      //   data.append(`${item}`, formValue[item]);
      // }

      let thumbnailUrl = null;
      if (formValue?.file) {
        const { url } = await uploadToCloudinary(formValue?.file);
        if (!url) {
          setIsLoading(false);
        } else {
          thumbnailUrl = url;
        }
      } else {
        toast.error("Required thumbnail image");
        setIsLoading(false);
        return;
      }

      let payload = formValue;
      if (thumbnailUrl) {
        delete payload.file;
        payload.thumbnail = thumbnailUrl;
      }

      try {
        let res = await createEvent(formValue);
        console.log(res);
        toast.success("News and Event created successfully");
        navigate("/events-list");
      } catch (e) {
        handleErrorBlock(e, navigate);
      }
    }
    setIsLoading(false);
  };

  const handleVerifyClick = async () => {
    try {
      const res = await verifyEventUniqueId(formValue.uniqueID);
      setValidId(res.data?.data?.unique);
    } catch (e) {
      handleErrorBlock(e, navigate);
    }
  };
  const uploadPicture = (e) => {
    if (e.target.files.length == 0) return;
    setImage(e.target.files[0]);
    setCropperModal(true);
  };

  const handleOnImageSelect = (value) => {
    setImgVal(value);
    let file = new File([value], image.name, { type: image.type });

    setFormValue({ ...formValue, file });
    setCropperModal(false);
  };

  return (
    <div className="p-4">
      <h1>Add News/Event</h1>
      <div className="d-flex flex-column mb-3">
        <label htmlFor="">News/Event ID</label>
        <div className="d-flex gap-2 ">
          <Input
            style={inputWidth}
            onChange={(e) => setFormValue({ ...formValue, uniqueID: e })}
          />
          <Button onClick={handleVerifyClick}>Verify</Button>
          {validId == false && (
            <div className="d-flex align-items-center">
              <MdOutlineCancel className="text-danger" size={30} />
              <p className={"text-danger"}>Unavailable</p>
            </div>
          )}
          {validId && (
            <div className="d-flex align-items-center ">
              <MdTaskAlt className="text-success" size={30} />
              <p className={"text-success"}>Available</p>
            </div>
          )}
        </div>
        <Form
          ref={formRef}
          fluid
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}
          model={model}
          className="mt-3 "
          onSubmit={handleSubmit}
        >
          <div className="d-flex flex-column mb-3">
            <label htmlFor="">Photo</label>
            <div className="position-relative">
              <Button
                style={{ width: "100px", zIndex: 0 }}
                onClick={() => {
                  document.getElementById("thumbnail-input").click();
                }}
              >
                Choose File
              </Button>
              <input
                type="file"
                id="thumbnail-input"
                style={{ width: "100px" }}
                className="position-absolute fixed-top invisible z-index-1 h-100"
                accept="image/*"
                onClick={(e) => {
                  e.target.value = null;
                }}
                onChange={uploadPicture}
              />
            </div>

            {image && cropperModal && (
              <CropperModal
                image={image}
                show={cropperModal}
                handleClose={() => {
                  setCropperModal(false);
                  setImage([]);
                  setImgVal(null);
                }}
                handleSubmit={handleOnImageSelect}
              />
            )}
            {imgVal && (
              <div
                style={{
                  position: "relative",
                  width: "400px",
                  marginTop: "5px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    right: 5,
                    top: 5,
                    cursor: "pointer",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    height: "20px",
                    width: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setImgVal(null);
                    setFormValue({ ...formValue, file: "" });
                  }}
                >
                  <IoCloseSharp />
                </div>
                <img src={imgVal} width="400px" />
              </div>
            )}
          </div>
          <TextInput style={inputWidth} name="title" label="Title" />
          <TextInput style={inputWidth} name="date" label="Date" type="date" />
          <TextInput style={inputWidth} name="excerpt" label="Excerpt" />
          <label className="d-block mb-1">Content</label>
          <TextEditorPage
            editorRef={editorRef}
            setFormValue={setFormValue}
            fromValue={formValue}
            initialContent="Text goes here..."
          />
          <ButtonToolbar>
            <Button
              appearance="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading && <div className="loader-dark mr-2"></div>}
              Submit
            </Button>
            <Button
              appearance="default"
              onClick={() => navigate("/events-list")}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        </Form>
      </div>
    </div>
  );
};

export default AddNewsEvent;
