import React, {useState} from 'react';
import {Button, Modal} from "rsuite";
import {deleteBlogComment} from "../services/blogs.service";
import {deleteEventComment} from "../services/events.service";
import {useNavigate} from "react-router-dom";
import {handleErrorBlock} from "../utils/errorBlock";

const CommentItem = ({data, blogid, type, update}) => {

const navigate = useNavigate();
    const [deleteDialog, setDeleteDialog] = useState(false);

    const deleteItem = async(commentId) => {
        try{
            const res = type === "blog" ? await deleteBlogComment(blogid, commentId): await deleteEventComment(blogid, commentId);
            console.log(res)
        }catch (e) {
            handleErrorBlock(e, navigate);
        }
        setDeleteDialog(false);
        update();
    }

    return (
        <div className="border border-white my-3 p-2 rounded" >
            <div className="d-flex justify-content-between " >
                <div>
                    <h5>{data.name}</h5>
                    <p>{data.created_at}</p>
                </div>
                <Button  appearance="link" color="red"  onClick={() => setDeleteDialog(true)} > Delete</Button>
            </div>
            <p className='py-3' >
                {data.content}
            </p>
            <Modal
                backdrop="static"
                role="alertdialog"
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                size="xs"
            >
                <Modal.Body>
                    Are you sure, you want to delete the faculty ?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => deleteItem(data._id)} appearance="primary">
                        Ok
                    </Button>
                    <Button onClick={() => setDeleteDialog(false)} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};



export default CommentItem;
