import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import LoginPage from "./pages/LoginPage";
import "rsuite/dist/rsuite.min.css";
import Layout from "./components/Layout";
import AddFaculty from "./pages/AddFaculty";
import Queries from "./pages/QueriesPage";
import UsersPage from "./pages/UsersPage";
import SignupPage from "./pages/SignupPage";
import { fetchJWTToken } from "./utils/authUtils";
import ForgetPassword from "./pages/Forgetpassword";
import PasswordReset from "./pages/PasswordReset";
import EditFaculty from "./pages/EditFaculty";
import AddUserPage from "./pages/AddUserPage";
import AddNewsEvent from "./pages/AddNewsEvent";
import TextEditorPage from "./pages/TextEditorPage";
import EventsList from "./pages/EventsList";
import BlogsList from "./pages/BlogsList";
import AddBlog from "./pages/AddBlog";
import EditNewsEvent from "./pages/EditNewsEvent";
import CommentsPage from "./pages/CommentsPage";

function App() {
  const jwtToken = fetchJWTToken();
  return (
    <Routes>
      <Route
        path="/"
        element={jwtToken ? <Layout content={<Home />} /> : <LoginPage />}
      />
      <Route
        path="/queries"
        element={jwtToken ? <Layout content={<Queries />} /> : <LoginPage />}
      />
      <Route
        path="/faculty-add"
        element={jwtToken ? <Layout content={<AddFaculty />} /> : <LoginPage />}
      />
      <Route
        path="/faculty-edit/:id"
        element={
          jwtToken ? <Layout content={<EditFaculty />} /> : <LoginPage />
        }
      />
      <Route
        path="/users"
        element={jwtToken ? <Layout content={<UsersPage />} /> : <LoginPage />}
      />
      <Route
        path="/users-add"
        element={
          jwtToken ? <Layout content={<AddUserPage />} /> : <LoginPage />
        }
      />
      <Route
        path="/news-event-add"
        element={
          jwtToken ? <Layout content={<AddNewsEvent />} /> : <LoginPage />
        }
      />
      <Route
        path="/news-event-edit/:id"
        element={
          jwtToken ? <Layout content={<EditNewsEvent />} /> : <LoginPage />
        }
      />
      <Route
        path="/events-list"
        element={jwtToken ? <Layout content={<EventsList />} /> : <LoginPage />}
      />
      <Route
        path="/blogs-list"
        element={jwtToken ? <Layout content={<BlogsList />} /> : <LoginPage />}
      />
      <Route
        path="/blog-add"
        element={jwtToken ? <Layout content={<AddBlog />} /> : <LoginPage />}
      />
        <Route
        path="/comments/:id"
        element={jwtToken ? <Layout content={<CommentsPage />} /> : <LoginPage />}
      />
      <Route path="forget-password" element={<ForgetPassword />} />
      <Route path="passwordReset" element={<PasswordReset />} />
      <Route path="register" element={<SignupPage />} />
    </Routes>
  );
}

export default App;
