export const filterOptions = [
    {
        label: "All",
        value: "All",
    },
    {
        label: "Draft",
        value: "Draft",
    },
    {
        label: "Unapproved",
        value: "Unapproved",
    },
    {
        label: "Published",
        value: "Published",
    },
];