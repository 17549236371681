import axios from "axios";
import { toast } from "react-toastify";

const uploadToCloudinary = async (file) => {
  toast.info("Uploading file...", { autoClose: 1000 });
  const url = `https://api.cloudinary.com/v1_1/tag-institute/upload`;
  const formData = new FormData();

  formData.append("file", file);
  formData.append("upload_preset", "ivcuda1n");

  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toast.success("Uploading file success", { autoClose: 1000 });
    return { url: response.data.secure_url };
  } catch (error) {
    console.error("Error uploading file to Cloudinary:", error);
    toast.error(error?.response?.data?.error?.message);
    return { url: null };
  }
};

export default uploadToCloudinary;
