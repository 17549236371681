import axios from "axios";
import {Endpoints} from "../Constants/Endpoints";
import {fetchJWTToken} from "../utils/authUtils";

const token = fetchJWTToken();

export function ImageUploadUtil(fd) {
    return axios({
        method: "POST",
        url: `${Endpoints.IMAGE_UPLOAD_URL}`,
        data: fd,
        headers: {
            "x-access-token":token,
            "content-type": "application/json",
        },
    });
}
