import axios from "axios";
import { Endpoints } from "../Constants/Endpoints";
import { fetchJWTToken } from "../utils/authUtils";

const token = fetchJWTToken();

export function verifyBlogUniqueId(id) {
    return axios({
        method: "POST",
        url: `${Endpoints.BLOG_VERIFY_ID}`,
        data:{id},
        headers: {
            "X-access-Token": token,
            "content-type": "application/json"
        },
    });
}

export function createBlog(data) {
    return axios({
        method:"POST",
        url:`${Endpoints.BLOG_URL}`,
        data:data,
        headers: {
            "X-access-Token": token,
            "content-type": "application/json"
        },
    })
}

export function getAllBlogs(type, page, count){
    return axios({
        method:"GET",
        url:`${Endpoints.BLOG_URL}?page=${page}&count=${count}&type=${type}`,
        headers: {
            "X-access-Token": token,
            "content-type": "application/json"
        },
    })
}

export function getSpecificBlog(id){
    return axios({
        method:"GET",
        url:`${Endpoints.BLOG_URL}/${id}`,
        headers: {
            "X-access-Token": token,
            "content-type": "application/json"
        },
    })
}

export function updateBlog(data, id) {
    return axios({
        method:"PUT",
        url:`${Endpoints.BLOG_URL}/${id}`,
        data:data,
        headers: {
            "X-access-Token": token,
            "content-type": "application/json"
        },
    })
}

export function getBlogComments(id, page , count){
    return axios({
        method: 'GET',
        url:`${Endpoints.BLOG_URL}/${id}/comments?page=${page}&count=${count}`,
        headers: {
            "X-access-Token": token,
            "content-type": "application/json"
        },
    })
}

export function deleteBlogComment(blogid, commentid){
    return axios({
        method: 'DELETE',
        url:`${Endpoints.BLOG_URL}/${blogid}/comments/${commentid}`,
        headers: {
            "X-access-Token": token,
            "content-type": "application/json"
        },
    })
}