import React from "react";
import {
  Button,
  ButtonToolbar,
  Container,
  Content,
  FlexboxGrid,
  Footer,
  Form,
  Panel,
  Schema,
} from "rsuite";
import { useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import {LoginUser, RegisterUser} from "../services/users.service";
import {handleErrorBlock} from "../utils/errorBlock";
import {saveJWTToken} from "../utils/authUtils";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {setUserProfileData} from "../redux/ActionCreator";

const LoginPage = () => {
  const [formValue, setFormValue] = React.useState({
    emailAddress: "",
    password: "",
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    emailAddress: Schema.Types.StringType()
      .isEmail("Please enter a valid email address.")
      .isRequired("This Field is required."),
    password: Schema.Types.StringType().isRequired("This field is required."),
  });

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const submitUserLogin = async () => {
    if (!formRef.current.check()) {
      toast.error('Error: Validation Failed')
    }else {
      try {
        const result = await LoginUser(formValue);
        console.log(result?.data.data)
        dispatch(setUserProfileData(result?.data?.data));
        saveJWTToken(result?.data?.data?.token);
        window.location.reload();
      } catch (e) {
        handleErrorBlock( e, navigate);
      }
    }
  };

  return (
    <Container className="vh-100 d-flex flex-column justify-content-between ">
      <Content className=" d-flex flex-column justify-content-center">
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={8}>
            <Panel header={<h3>Login Here</h3>} bordered>
              <Form
                ref={formRef}
                fluid
                onSubmit={submitUserLogin}
                onChange={setFormValue}
                onCheck={setFormError}
                formValue={formValue}
                model={model}
              >
                <TextInput
                  label="Username or email address"
                  type="email"
                  name="emailAddress"
                />
                <TextInput label="Password" type="password" name="password" />
                <ButtonToolbar>
                  <Button type="submit" appearance="primary">
                    Sign in
                  </Button>
                  {/*<Button appearance="link">Forgot password?</Button>*/}
                </ButtonToolbar>
              </Form>
              <Button onClick={() => navigate('/forget-password',{replace: true})} appearance="link" className=" mt-3" >Forgot Password?</Button>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
      <Footer>Footer DATA HERE</Footer>
    </Container>
  );
};

export default LoginPage;
