import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "rsuite";
import { getQueryList } from "../services/queries.service";
import QueryItem from "./QueryItem";
import ReactPaginate from "react-paginate";
import { handleErrorBlock } from "../utils/errorBlock";
import Spinner from "../components/Spinner";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";

const Queries = () => {
  const navigate = useNavigate();
  const [queryStatus, setQueryStatus] = useState("All");
  const [queryType, setQueryType] = useState("Home");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [update, setUpdate] = useState(true);
  const [count, setCount] = useState(10);
  const [loading, setLoading] = useState(true);

  const [csvData, setCsvData] = useState([]);

  console.log(csvData);

  useEffect(() => {
    getCsvData();
  }, []);

  const getCsvData = async () => {
    try {
      const res = await getQueryList("All", "All", 10000, 1);
      setCsvData(
        res?.data?.data.map(
          ({
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
            type,
            city = "",
            enquiry = "",
            utm_source = "",
            utm_medium = "",
            utm_campaign = "",
            utm_term = "",
            utm_content = "",
            utm_id = "",
            createdAt = "",
          }) => ({
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
            type,
            city,
            enquiry,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content,
            utm_id,
            createdAt: createdAt
              ? JSON.stringify(
                  `${dayjs(createdAt).format("DD/MM/YYYY HH:MM A")}`
                )
              : "-",
          })
        )
      );
    } catch (e) {
      handleErrorBlock(e, navigate);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getQueryList(queryType, queryStatus, count, page);
        setData(res?.data?.data);
        setPage(res?.data?.meta?.page);
        setTotalPages(res?.data?.meta?.pagesCount);
      } catch (e) {
        handleErrorBlock(e, navigate);
      }
    })();
    setLoading(false);
  }, [update, queryType, queryStatus]);

  const handlePageClick = (e) => {
    console.log(e);
    setPage(e.selected + 1);
    setUpdate(!update);
  };

  return (
    <>
      {loading && <Spinner />}
      <div className=" d-flex flex-column p-4 vh-100">
        <div className="d-flex justify-content-between align-items-center mb-3 ">
          <h2 className="display-4">Queries</h2>
        </div>
        <div
          className="d-flex flex-column justify-content-between "
          style={{ flex: 1 }}
        >
          <div>
            <div className="d-flex justify-content-between mb-5 ">
              <div className="">
                <label className="me-4" htmlFor="">
                  Query Status
                </label>
                <Dropdown
                  title={queryStatus}
                  activeKey={queryStatus}
                  onSelect={(e) => setQueryStatus(e)}
                >
                  <Dropdown.Item eventKey="All">All</Dropdown.Item>
                  <Dropdown.Item eventKey="resolved">Resolved</Dropdown.Item>
                  <Dropdown.Item eventKey="unresolved">
                    Unresolved
                  </Dropdown.Item>
                </Dropdown>
              </div>
              <div className="">
                <label className="me-4" htmlFor="">
                  Form Type
                </label>
                <Dropdown
                  title={queryType}
                  activeKey={queryType}
                  onSelect={(e) => setQueryType(e)}
                >
                  <Dropdown.Item eventKey="Home">Home</Dropdown.Item>
                  <Dropdown.Item eventKey="Prospectus">
                    Prospectus
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Landing">Landing</Dropdown.Item>
                  <Dropdown.Item eventKey="All">All</Dropdown.Item>
                </Dropdown>
              </div>
              <div>
                <CSVLink
                  filename="leads.csv"
                  className="btn btn-primary"
                  data={csvData}
                >
                  Download Excel
                </CSVLink>
              </div>
            </div>
            <div>
              {data.length === 0 && !loading ? (
                <div className="d-flex justify-content-center text-center">
                  <h1 className="my-5 display-2">Nothing to show here!</h1>
                </div>
              ) : (
                data.map((item) => (
                  <QueryItem
                    key={item._id}
                    data={item}
                    update={() => setUpdate(!update)}
                  />
                ))
              )}
            </div>
          </div>
          <div className="pt-3">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={totalPages}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Queries;
