import React, { createRef } from "react";
import { Button, Modal } from "rsuite";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropperModal = ({ image, show, handleClose, handleSubmit }) => {
  const cropperRef = createRef();

  const getCropData = (e) => {
    e.preventDefault();
    if (typeof cropperRef.current?.cropper !== "undefined") {
      handleSubmit(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };
  return (
    <Modal
      open={show}
      onClose={() => {
        handleClose();
      }}
    >
      <Modal.Body>
        <Cropper
          ref={cropperRef}
          style={{ height: 400, width: "100%" }}
          zoomTo={0.5}
          initialAspectRatio={16 / 9}
          aspectRatio={16 / 9}
          preview=".img-preview"
          src={URL.createObjectURL(image)}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={getCropData} appearance="primary">
          Crop
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CropperModal;
