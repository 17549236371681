export class Endpoints {
  static BASE_URL = "https://api.tagmumbai.com";
  // static BASE_URL = "http://localhost:5000";

  static USERS_URL = `${Endpoints.BASE_URL}/users`;

  static BLOG_URL = `${Endpoints.BASE_URL}/blogs`;

  static QUERIES_URL = `${Endpoints.BASE_URL}/queries`;

  static EVENTS_URL = `${Endpoints.BASE_URL}/events`;

  static FACULTY_URL = `${Endpoints.BASE_URL}/faculty`;

  static LOGIN = `${Endpoints.USERS_URL}/login`;

  static FORGOT_PASSWORD = `${Endpoints.USERS_URL}/forgot-password`;

  static RESET_PASSWORD = `${Endpoints.USERS_URL}/reset-password`;

  static REGISTER = `${Endpoints.USERS_URL}/register`;

  static MENTOR_LIST = `${Endpoints.FACULTY_URL}?type=Mentor`;

  static WPL_LIST = `${Endpoints.FACULTY_URL}?type=WPL`;

  static SOCIAL_USER_LIST = `${Endpoints.BASE_URL}/users?type=Social`;

  static SALES_USER_LIST = `${Endpoints.BASE_URL}/users?type=Sales`;

  static ADD_USER = `${Endpoints.USERS_URL}/register`;

  static IMAGE_UPLOAD_URL = `${Endpoints.BASE_URL}/helpers/text-editor/image-upload`;

  static BLOG_VERIFY_ID = `${Endpoints.BLOG_URL}/verify-id`;

  static EVENTS_VERIFY_ID = `${Endpoints.EVENTS_URL}/verify-id`;
}
