import React from "react";
import {
  Button,
  ButtonToolbar,
  Form,
  Input,
  Schema,
  SelectPicker,
} from "rsuite";
import { useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import { toast } from "react-toastify";
import { CreateNewUser } from "../services/users.service";
import { handleErrorBlock } from "../utils/errorBlock";

const TypeData = [
  {
    label: "Social",
    value: "Social",
  },
  {
    label: "Sales",
    value: "Sales",
  },
];

const inputWidth = {
  width: "clamp(300px, 40vw, 500px)",
};
const AddUserPage = () => {
  const [formValue, setFormValue] = React.useState({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
    type: "",
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    name: Schema.Types.StringType().isRequired("Name Cannot be empty"),
    emailAddress: Schema.Types.StringType()
      .isEmail("Please enter a valid email address.")
      .isRequired("This Field is required."),
    password: Schema.Types.StringType()
      .minLength(6)
      .isRequired("This field is required."),
    phoneNumber: Schema.Types.NumberType().isRequired(
      "This field is required."
    ),
  });

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      toast.error("Please Check all the fields");
    } else {
      try {
        const res = await CreateNewUser(formValue);
        console.log(res.data);
        toast.success('Created User Successfully');
        setTimeout(() => {navigate('/users')},1000)
      } catch (e) {
        handleErrorBlock(e, navigate);
      }
    }
  };

  return (
    <div className="p-4">
      <h1>Add User</h1>
      <Form
        ref={formRef}
        fluid
        onChange={setFormValue}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        className="mt-3 "
        onSubmit={handleSubmit}
      >
        <TextInput style={inputWidth} name="name" label="Name" />
        <TextInput
          style={inputWidth}
          name="phoneNumber"
          label="Phone Number"
          type="number"
        />
        <TextInput
          style={inputWidth}
          name="emailAddress"
          label="Email Address"
          type="email"
        />
        <TextInput
          style={inputWidth}
          name="password"
          label="Password"
          type="password"
        />
        <label htmlFor="" className="d-block mb-1">
          Select Type
        </label>
        <SelectPicker
          name="type"
          data={TypeData}
          searchable={false}
          style={{ width: 224, marginBottom: 10 }}
          onChange={(e) => setFormValue({ ...formValue, type: e })}
        />
        <ButtonToolbar>
          <Button appearance="primary" type="submit">
            Submit
          </Button>
          <Button appearance="default" onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </ButtonToolbar>
      </Form>
    </div>
  );
};

export default AddUserPage;
