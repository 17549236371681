import React from 'react';
import {Loader} from "rsuite";

const Spinner = () => {
    return (
        <div className="d-flex justify-content-center align-items-center vh-100 vw-100 position-absolute" style={{width:"100vw"}} >
            <Loader size="lg" content="Loading" />
        </div>
    );
};

export default Spinner;
