import { toast } from "react-toastify";
import {removeJWTToken} from "./authUtils";



export const handleErrorBlock = (
    error,
    history,
) => {
    console.log("Universal Error: Status", error?.response.status);
    console.log("Universal Error: Data", error?.response?.data?.data);
    if (
        error?.response?.status === 401 ||
        error?.response?.data?.status === 401
    ) {
        removeJWTToken();
        window.location.reload();
        toast.error("Unauthorized Error, Please login again");
    } else {
        if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message +": "+ error?.response?.data?.data);
        } else {
            toast.error("Site is under maintanance, we will be up very soon!");
        }
    }
};