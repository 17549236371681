import {
  SET_USER_PROFILE_DATA,
  UPDATE_USER_PREFERENCE,
  CLEAR_REDUX_INITIAL,
} from "./ActionTypes";

const initialState = {
  profileDetails: "",
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_REDUX_INITIAL:
      return {
        ...state,
        profileDetails: "",
      };
    case SET_USER_PROFILE_DATA:
      return {
        ...state,
        profileDetails: action.payload,
      };
    case UPDATE_USER_PREFERENCE:
      return {
        ...state,
        profileDetails: {
          ...state.profileDetails,
        },
      };
    default:
      return state;
  }
};

export default AppReducer;
