import React from "react";
import FacultyItem from "./FacultyItem";
import { useEffect, useState } from "react";
import {GetMentorList, GetWPLList} from "../services/faculty.service";
import ReactPaginate from "react-paginate";
import {handleErrorBlock} from "../utils/errorBlock";
import {useNavigate} from "react-router-dom";
import Spinner from "./Spinner";

const FacultyWPLPanel = () => {
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState([]);
    const [update, setUpdate] = useState(true);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const res = await GetWPLList(page, count);
                setPage(res?.data?.meta?.page);
                setTotalPages(res?.data?.meta?.pagesCount);
                setData(res?.data?.data);
            } catch (e) {
                handleErrorBlock(e, navigate)
            }
        })();
        setLoading(false);
    }, [page, count, update]);

    const handlePageClick = (e) => {
        setPage(e.selected + 1);
    };

    return (
        <div>
            {loading && <Spinner />}
            {(data.length === 0 && !loading) ? <div className="d-flex justify-content-center text-center" >
                <h1 className="my-5 display-2" >Nothing to show here!</h1>
            </div>:data.map((item) => (
                <FacultyItem
                    key={item._id}
                    name={item.name}
                    photo={item.photo}
                    id={item._id}
                    update={() => setUpdate(!update)}
                    data={item}
                />
            ))}
            <div className="pt-3">
                <ReactPaginate
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={totalPages}
                    previousLabel="< previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </div>
    );
};

export default FacultyWPLPanel;
