import React from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import { FaGem, FaHeart } from "react-icons/fa";
import { Button } from "rsuite";
import { removeJWTToken } from "../utils/authUtils";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearReduxInitial} from "../redux/ActionCreator";

const Sidebar = () => {
  const navigate = useNavigate();
  const profileDetails = useSelector(({ app }) => app.profileDetails);
  const dispatch = useDispatch();
  // console.log(profileDetails);
  const handleLogout = () => {
    removeJWTToken();
    // console.log("logout initiating ");
    dispatch(clearReduxInitial)
    window.location.reload();
  };

  return (
    <ProSidebar className=" d-flex flex-column justify-content-between">
      <SidebarHeader className="p-3">
        <div>
          <h2>{profileDetails.name}</h2>
          <p>{profileDetails.emailAddress}</p>
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="square">
          {profileDetails.type === "Super Admin" && (
            <>
              <MenuItem onClick={() => navigate("/")} icon={<FaGem />}>
                Faculty
              </MenuItem>
              <MenuItem onClick={() => navigate("/users")} icon={<FaGem />}>
                Users
              </MenuItem>
              <MenuItem onClick={() => navigate("/queries")} icon={<FaGem />}>
                Queries
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/events-list")}
                icon={<FaGem />}
              >
                Events/News
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/blogs-list")}
                icon={<FaGem />}
              >
                Blogs
              </MenuItem>
            </>
          )}
          {profileDetails.type === "Sales" && (
            <>
              <MenuItem onClick={() => navigate("/queries")} icon={<FaGem />}>
                Queries
              </MenuItem>
            </>
          )}
          {profileDetails.type === "Social" && (
            <>
              <MenuItem
                onClick={() => navigate("/events-list")}
                icon={<FaGem />}
              >
                Events/News
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/blogs-list")}
                icon={<FaGem />}
              >
                Blogs
              </MenuItem>
            </>
          )}
        </Menu>
      </SidebarContent>
      <SidebarFooter className="p-3">
        <Button block appearance="ghost" onClick={handleLogout}>
          Logout
        </Button>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;
