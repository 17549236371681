
import "react-pro-sidebar/dist/css/styles.css";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

const Layout = ({ content }) => {
  return (
    <div className="layout-wrapper vh-100 ">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className="mobile-navbar-container">
        <Navbar />
      </div>
      <div style={{ maxHeight: "100vh", width: "100%", overflowY: "scroll" }}>
        {content}
      </div>
    </div>
  );
};

export default Layout;
