import React from "react";
import {
  Button,
  ButtonToolbar,
  Container,
  Content,
  Dropdown,
  FlexboxGrid,
  Footer,
  Form,
  Header,
  Message,
  Panel,
  Schema, SelectPicker,
  toaster,
} from "rsuite";
import TextInput from "../components/TextInput";
import { RegisterUser } from "../services/users.service";
import { handleErrorBlock } from "../utils/errorBlock";
import { useNavigate } from "react-router-dom";
import {toast} from "react-toastify";

const userTypes =[
  {
    "label": "Social",
    "value": "Social",
  },
  {
    "label": "Sales",
    "value": "Sales",
  },
]

const SignupPage = () => {
  const [formValue, setFormValue] = React.useState({
    name: "",
    phoneNumber: "",
    emailAddress: "",
    password: "",
    type:"",
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    name: Schema.Types.StringType().isRequired("Name Cannot be empty"),
    phoneNumber: Schema.Types.NumberType().isRequired("Phone Number is Required"),
    emailAddress: Schema.Types.StringType()
      .isEmail("Please enter a valid email address.")
      .isRequired("This Field is required."),
    password: Schema.Types.StringType().minLength(6).isRequired("This field is required."),
    type: Schema.Types.StringType().minLength(3).isRequired("This field is required")
  });

  let navigate = useNavigate();

  const submitUserRegistration = async () => {
    console.log(formValue);
    if (!formRef.current.check()) {
      toast.error('Please Check all the fields');
    }else{
      try {
        const result = await RegisterUser(formValue);
        console.log(result);
      } catch (e) {
        handleErrorBlock( e, navigate);
      }
    }

  };

  return (
    <Container className="vh-100 d-flex flex-column justify-content-between ">
      <Content className=" d-flex flex-column justify-content-center">
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={8}>
            <Panel header={<h3>Create Account</h3>} bordered>
              <Form
                ref={formRef}
                fluid
                onSubmit={submitUserRegistration}
                onChange={setFormValue}
                onCheck={setFormError}
                formValue={formValue}
                model={model}
              >
                <TextInput label="Your Name" type="text" name="name" />
                <TextInput label="Phone Number" type="number" name="phoneNumber" />
                <TextInput
                  label="Username or email address"
                  type="email"
                  name="emailAddress"
                />
                <TextInput label="Password" type="password" name="password" />
                <SelectPicker name="type" onSelect={e => setFormValue({...formValue, type:e})} data={userTypes}/>
                <ButtonToolbar>
                  <Button type="submit" appearance="primary">
                    Sign in
                  </Button>

                </ButtonToolbar>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
      <Footer>Footer</Footer>
    </Container>
  );
};

export default SignupPage;
