import axios from "axios";
import { Endpoints } from "../Constants/Endpoints";
import {fetchJWTToken} from "../utils/authUtils";

const token = fetchJWTToken();

export function RegisterUser(data) {
  return axios({
    method: "POST",
    url: `${Endpoints.REGISTER}`,
    data
  });
}

export function LoginUser(data) {
  return axios({
    method: "POST",
    url: `${Endpoints.LOGIN}`,
    data
  });
}

export function PasswordResetRequest(data) {
  return axios({
    method: "POST",
    url:
        `${Endpoints.FORGOT_PASSWORD}`,
    data
  })
}

export function ChangePassword(data) {
  return axios({
    method: "POST",
    url:
        `${Endpoints.RESET_PASSWORD}`,
    data
  })
}

export function GetSalesUserList(page, count) {
  return axios({
    method: "GET",
    url: `${Endpoints.SALES_USER_LIST}&page=${page}&count=${count}`,
      headers: {
        "X-access-Token": token,
        "content-type": "application/json"
    },
  });
}
export function GetSocialUserList(page, count) {
  return axios({
    method: "GET",
    url: `${Endpoints.SOCIAL_USER_LIST}&page=${page}&count=${count}`,
      headers: {
        "X-access-Token": token,
        "content-type": "application/json"
    },
  });
}

export function DeleteUser(id) {
  return axios({
    method: "DELETE",
    url: `${Endpoints.USERS_URL}/${id}`,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json"
    },
  });
}

export function CreateNewUser(data) {
  return axios({
    method:"POST",
    url:`${Endpoints.ADD_USER}`,
    data,
    headers:{
      "X-access-Token": token,
      "content-type": "application/json"
    },
  });
}
