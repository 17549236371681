import React, { useEffect } from "react";
import {
  Button,
  ButtonToolbar,
  Container,
  Content,
  FlexboxGrid,
  Footer,
  Form,
  Panel,
  Schema,
} from "rsuite";
import TextInput from "../components/TextInput";
import { ChangePassword, LoginUser } from "../services/users.service";
import { saveJWTToken } from "../utils/authUtils";
import { handleErrorBlock } from "../utils/errorBlock";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const PasswordReset = () => {
  const [formValue, setFormValue] = React.useState({
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    password: Schema.Types.StringType().isRequired("This field is required."),
    confirmPassword: Schema.Types.StringType()
      .addRule((value, data) => {
        console.log(data);
        return value === data.password;
      }, "The two passwords do not match")
      .isRequired("This field is required."),
  });

  const submitChangePassword = async () => {
    if (!formRef.current.check()) {
      toast.error("Please Check all the fields");
    } else {
      try {
        const result = await ChangePassword({
          userId: searchParams.get("id"),
          token: searchParams.get("token"),
          password: formValue.password,
        });
        if (result.data.status === 200) {
          toast.success("password changed successfully");
        }
        console.log(result.data);
      } catch (e) {
        handleErrorBlock(e, navigate);
      }
    }
  };
  const [searchParams, setSearchParams] = useSearchParams();

  searchParams.get("token");
  return (
    <Container className="vh-100 d-flex flex-column justify-content-between ">
      <Content className=" d-flex flex-column justify-content-center">
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={8}>
            <Panel header={<h3>Reset Password</h3>} bordered>
              <Form
                ref={formRef}
                fluid
                onSubmit={submitChangePassword}
                onChange={setFormValue}
                onCheck={setFormError}
                formValue={formValue}
                model={model}
              >
                <TextInput
                  label="Enter New Password"
                  type="password"
                  name="password"
                />
                <TextInput
                  label="Confirm New Password"
                  type="password"
                  name="confirmPassword"
                />
                <ButtonToolbar>
                  <Button type="submit" appearance="primary">
                    Reset Password
                  </Button>
                </ButtonToolbar>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  );
};

export default PasswordReset;
