import axios from "axios";
import { Endpoints } from "../Constants/Endpoints";
import { fetchJWTToken } from "../utils/authUtils";

const token = fetchJWTToken();

export function verifyEventUniqueId(id) {
  return axios({
    method: "POST",
    url: `${Endpoints.EVENTS_VERIFY_ID}`,
    data: { id },
    headers: {
      "X-access-Token": token,
      "content-type": "application/json",
    },
  });
}

export function createEvent(data) {
  return axios({
    method: "POST",
    url: `${Endpoints.EVENTS_URL}`,
    data: data,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json",
    },
  });
}

export function getAllEvents(type, page, count) {
  return axios({
    method: "GET",
    url: `${Endpoints.EVENTS_URL}?page=${page}&count=${count}&type=${type}`,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json",
    },
  });
}

export function getSpecificEvent(id) {
  return axios({
    method: "GET",
    url: `${Endpoints.EVENTS_URL}/${id}`,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json",
    },
  });
}

export function updateEvent(data, id) {
  console.log(data, "this is the data that axios will receive");
  return axios({
    method: "PUT",
    url: `${Endpoints.EVENTS_URL}/${id}`,
    data: data,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json",
    },
  });
}

export function deleteEvent(id) {
  return axios({
    method: "DELETE",
    url: `${Endpoints.EVENTS_URL}/${id}`,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json",
    },
  });
}

export function updateEventStage(id, stage) {
  return axios({
    method: "PUT",
    url: `${Endpoints.EVENTS_URL}/${id}/update-stage`,
    data: {
      stage,
    },
    headers: {
      "X-access-Token": token,
      "content-type": "application/json",
    },
  });
}

export function updateBlogStage(id, stage) {
  return axios({
    method: "PUT",
    url: `${Endpoints.BLOG_URL}/${id}/update-stage`,
    data: {
      stage,
    },
    headers: {
      "X-access-Token": token,
      "content-type": "application/json",
    },
  });
}

export function getEventComments(id, page, count) {
  return axios({
    method: "GET",
    url: `${Endpoints.EVENTS_URL}/${id}/comments?page=${page}&count=${count}`,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json",
    },
  });
}

export function deleteEventComment(blogid, commentid) {
  return axios({
    method: "DELETE",
    url: `${Endpoints.EVENTS_URL}/${blogid}/comments/${commentid}`,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json",
    },
  });
}
