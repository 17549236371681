import React, { useRef, useState } from "react";
import {
    Button,
    ButtonToolbar,
    Form,
    Input,
    Schema,
} from "rsuite";
import TextInput from "../components/TextInput";
import { useNavigate } from "react-router-dom";
import TextEditorPage from "./TextEditorPage";
import { handleErrorBlock } from "../utils/errorBlock";
import { MdOutlineCancel, MdTaskAlt } from "react-icons/md";
import {toast} from "react-toastify";
import {createBlog, verifyBlogUniqueId} from "../services/blogs.service";

const inputWidth = {
    width: "clamp(100px, 40vw, 200px)",
};

const AddBlog = () => {
    const editorRef = useRef("");
    const [validId, setValidId] = useState(null);
    const [formValue, setFormValue] = React.useState({
        title: "",
        excerpt: "",
        content: "",
        file: "",
        uniqueID: "",
    });
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    const model = Schema.Model({
        title: Schema.Types.StringType().isRequired("Name Cannot be empty"),
        excerpt: Schema.Types.StringType().isRequired("This Field Required"),
        content: Schema.Types.StringType().isRequired("This Field is required."),
    });

    const navigate = useNavigate();

    const handleSubmit = async () => {
        if (!formRef.current.check() || !validId) {
            toast.error("Please Check all the fields");
        } else {
            const data = new FormData();
            for (let item in formValue) {
                data.append(`${item}`, formValue[item]);
            }
            try {
                let res = await createBlog(data);
                console.log(res);
                toast.success("Faculty created successfully");
                setTimeout(() => {
                    navigate("/");
                }, 1000);
            } catch (e) {
                handleErrorBlock(e, navigate);
            }
        }
    };

    const handleVerifyClick = async () => {
        try {
            const res = await verifyBlogUniqueId(formValue.uniqueID);
            setValidId(res.data?.data?.unique);
        } catch (e) {
            handleErrorBlock(e, navigate);
        }
    };
    const uploadPicture = (e) => {
        setFormValue({ ...formValue, file: e.target.files[0] });
    };

    return (
        <div className="p-4">
            <h1>Add Blog</h1>
            <div className="d-flex flex-column mb-3">
                <label htmlFor="">News/Event ID</label>
                <div className="d-flex gap-2 ">
                    <Input
                        style={inputWidth}
                        onChange={(e) => setFormValue({ ...formValue, uniqueID: e })}
                    />
                    <Button onClick={handleVerifyClick}>Verify</Button>
                    {validId === false && (
                        <div className="d-flex align-items-center">
                            <MdOutlineCancel className="text-danger" size={30} />
                            <p className={"text-danger"}>Unavailable</p>
                        </div>
                    )}
                    {validId && (
                        <div className="d-flex align-items-center " >
                            <MdTaskAlt className="text-success" size={30} />
                            <p className={"text-success"}>Available</p>
                        </div>
                    )}
                </div>
                <Form
                    ref={formRef}
                    fluid
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={model}
                    className="mt-3 "
                    onSubmit={handleSubmit}
                >
                    <div className="d-flex flex-column mb-3">
                        <label htmlFor="">Photo</label>
                        <input type="file" onChange={uploadPicture} />
                        {formValue?.file && (
                            <img
                                src={URL.createObjectURL(formValue.file) || ""}
                                height="200px"
                                width="200px"
                            />
                        )}
                    </div>
                    <TextInput
                        style={inputWidth}
                        name="title"
                        label="Title"
                    />
                    <TextInput
                        style={inputWidth}
                        name="excerpt"
                        label="Excerpt"
                    />
                    <label className="d-block mb-1">Content</label>
                    <TextEditorPage
                        editorRef={editorRef}
                        setFormValue={setFormValue}
                        initialContent="Text goes here..."
                    />
                    <ButtonToolbar>
                        <Button appearance="primary" type="submit">
                            Submit
                        </Button>
                        <Button appearance="default" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </Form>
            </div>
        </div>
    );
};

export default AddBlog;
