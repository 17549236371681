import React, { useState } from "react";
import { Button, Modal } from "rsuite";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleErrorBlock } from "../utils/errorBlock";
import { deleteQuery, markResolveQuery } from "../services/queries.service";
import dayjs from "dayjs";

const QueryItem = ({ data, update }) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [resolveDialog, setResolveDialog] = useState(false);

  const navigate = useNavigate();
  const handleDeleteUser = async () => {
    try {
      const res = await deleteQuery(data?._id);
      toast.success("deletion Success");
      setDeleteDialog(false);
      update();
    } catch (e) {
      handleErrorBlock(e, navigate);
    }
  };

  const handleResolveQuery = async () => {
    try {
      const res = await markResolveQuery(data?._id);
      setResolveDialog(false);
      update();
    } catch (e) {
      handleErrorBlock(e, navigate);
    }
  };
  return (
    <div style={{ fontSize: 12 }} className=" border p-3 rounded-3 mb-3">
      <div className="row">
        <div className="col-lg-2 text-break">
          {data?.firstName}&nbsp;
          {data?.lastName}
        </div>
        <div className="col-lg-2 text-break">{data?.emailAddress}</div>
        <div className="col-lg-1">{data?.phoneNumber}</div>
        <div className="col-lg-2">
          {data?.city ? (
            <div className="text-primary">City: {data?.city}</div>
          ) : (
            <></>
          )}
          {data?.enquiry ? (
            <div className="text-primary">Enquiry: {data?.enquiry}</div>
          ) : (
            <></>
          )}
        </div>

        <div className="col-lg-3 d-lg-flex gap-2 justify-content-between align-items-start pe-3">
          <div>
            {!data?.utm_source &&
              !data?.utm_medium &&
              !data?.utm_campaign &&
              !data?.utm_term &&
              !data?.utm_content &&
              !data?.utm_id && <div className="text-primary">N/A</div>}
            {data?.utm_source ? (
              <div className="text-primary">
                UTM Source: {data?.utm_source} <br />
              </div>
            ) : (
              <></>
            )}
            {data?.utm_medium ? (
              <div className="text-primary">
                UTM Medium: {data?.utm_medium} <br />
              </div>
            ) : (
              <></>
            )}
            {data?.utm_campaign ? (
              <div className="text-primary">
                UTM Campaign: {data?.utm_campaign} <br />
              </div>
            ) : (
              <></>
            )}
            {data?.utm_term ? (
              <div className="text-primary">
                UTM Term: {data?.utm_term} <br />
              </div>
            ) : (
              <></>
            )}
            {data?.utm_content ? (
              <div className="text-primary">
                UTM Content: {data?.utm_content} <br />
              </div>
            ) : (
              <></>
            )}
            {data?.utm_id ? (
              <div className="text-primary">UTM ID: {data?.utm_id}</div>
            ) : (
              <></>
            )}
          </div>
          {data?.createdAt && (
            <div style={{ fontSize: 12 }} className="">
              {dayjs(data?.createdAt).format("DD/MM/YYYY HH:MM A")}
            </div>
          )}
          <div style={{ fontSize: 12 }} className="btn btn-outline-light">
            {data?.type?.toUpperCase()}
          </div>
        </div>

        <div className="col-lg-2 d-flex justify-content-end align-items-start">
          {data?.resolved ? (
            <div
              className="d-flex justify-content-center align-items-center py-1 fw-bold"
              style={{
                backgroundColor: "#112635",
                color: "#4A9CD9",
                fontSize: 12,
              }}
            >
              Resolved
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <Button
                style={{ fontSize: 12 }}
                appearance="link"
                onClick={() => setResolveDialog(true)}
              >
                Mark as Resolved
              </Button>
              <Button
                style={{ fontSize: 12 }}
                appearance="link"
                className="text-danger"
                onClick={() => setDeleteDialog(true)}
              >
                Delete
              </Button>
            </div>
          )}
        </div>
      </div>

      <Modal
        backdrop="static"
        role="alertdialog"
        open={resolveDialog}
        onClose={() => setResolveDialog(false)}
        size="xs"
      >
        <Modal.Body>
          Are you sure, you want to Mark this query as resolved ?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleResolveQuery} appearance="primary">
            Ok
          </Button>
          <Button onClick={() => setResolveDialog(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        backdrop="static"
        role="alertdialog"
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        size="xs"
      >
        <Modal.Body>Are you sure, you want to delete the faculty ?</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDeleteUser} appearance="primary">
            Ok
          </Button>
          <Button onClick={() => setDeleteDialog(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default QueryItem;
