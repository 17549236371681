import axios from "axios";
import { Endpoints } from "../Constants/Endpoints";
import { fetchJWTToken } from "../utils/authUtils";
const token = fetchJWTToken();

export function getQueryList(tab, type, count, page) {
    return axios({
        method: "GET",
        url: `${Endpoints.QUERIES_URL}?type=${type}&page=${page}&count=${count}&tab=${tab}`,
        headers: {
            "X-access-Token": token,
            "content-type": "application/json"
        }
    })
}

export function deleteQuery(id) {
    return axios({
        method: "DELETE",
        url: `${Endpoints.QUERIES_URL}/${id}`,
        headers: {
            "X-access-Token": token,
            "content-type": "application/json"
        },
    });
}
export function markResolveQuery(id) {
    return axios({
        method: "PUT",
        url: `${Endpoints.QUERIES_URL}/${id}`,
        headers: {
            "X-access-Token": token,
            "content-type": "application/json"
        },
    });
}