import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Form,
  Input,
  Loader,
  Schema,
  SelectPicker,
} from "rsuite";
import TextInput from "../components/TextInput";
import { useNavigate, useParams } from "react-router-dom";
import TextEditorPage from "./TextEditorPage";
import {
  createEvent,
  getSpecificEvent,
  updateEvent,
  verifyEventUniqueId,
} from "../services/events.service";
import { handleErrorBlock } from "../utils/errorBlock";
import { MdOutlineCancel, MdTaskAlt } from "react-icons/md";
import { toast } from "react-toastify";
import { CreateFaculty } from "../services/faculty.service";
import CropperModal from "../components/CropperModal";
import uploadToCloudinary from "../utils/uploadToCloudinary";
const inputWidth = {
  width: "clamp(100px, 40vw, 200px)",
};

const EditNewsEvent = () => {
  const editorRef = useRef("");
  const [validId, setValidId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cropperModal, setCropperModal] = useState(false);
  const [image, setImage] = useState([]);
  const [imgVal, setImgVal] = useState(null);
  const [formValue, setFormValue] = React.useState({
    title: "",
    excerpt: "",
    content: "",
    file: "",
    uniqueID: "",
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    title: Schema.Types.StringType().isRequired("Name Cannot be empty"),
    excerpt: Schema.Types.StringType().isRequired("This Field Required"),
    content: Schema.Types.StringType().isRequired("This Field is required."),
  });

  const navigate = useNavigate();
  let { id } = useParams();
  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const res = await getSpecificEvent(id);
        let data = res.data.data;
        setFormValue({
          title: data.title,
          excerpt: data.excerpt,
          content: data.content,
          uniqueID: data.uniqueID,
          thumbnail: data.thumbnail,
          file: null,
        });
        setLoading(false);
      } catch (e) {
        handleErrorBlock(e, navigate);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      toast.error("Please Check all the fields");
    } else {
      // const data = new FormData();
      // for (let item in formValue) {
      //   data.append(`${item}`, formValue[item]);
      // }

      let thumbnailUrl = null;
      if (formValue?.file) {
        const { url } = await uploadToCloudinary(formValue?.file);
        if (!url) {
          setIsLoading(false);
        } else {
          thumbnailUrl = url;
        }
      }

      let payload = formValue;
      if (thumbnailUrl) {
        delete payload.file;
        payload.thumbnail = thumbnailUrl;
      }

      try {
        let res = await updateEvent(payload, id);
        console.log(res);
        toast.success("Event Updated Successfully");
        navigate("/events-list");
      } catch (e) {
        handleErrorBlock(e, navigate);
      }
    }
  };

  const handleOnImageSelect = (value) => {
    setImgVal(value);
    let file = new File([value], image.name, { type: image.type });

    setFormValue({ ...formValue, file });
    setCropperModal(false);
  };

  const uploadPicture = (e) => {
    if (e.target.files.length == 0) return;
    setImage(e.target.files[0]);
    setCropperModal(true);
  };

  return (
    <div className="p-4">
      <h1>Edit News/Event</h1>
      {loading ? (
        <Loader size="lg" content="Large" />
      ) : (
        <div className="d-flex flex-column mb-3">
          <Form
            ref={formRef}
            fluid
            onChange={setFormValue}
            onCheck={setFormError}
            formValue={formValue}
            model={model}
            className="mt-3 "
            onSubmit={handleSubmit}
          >
            <div className="d-flex flex-column mb-3">
              <label htmlFor="">Photo</label>

              <div className="position-relative">
                <Button
                  style={{ width: "100px", zIndex: 0 }}
                  onClick={() => {
                    document.getElementById("thumbnail-input").click();
                  }}
                >
                  Choose File
                </Button>
                <input
                  type="file"
                  id="thumbnail-input"
                  style={{ width: "100px" }}
                  className="position-absolute fixed-top invisible z-index-1 h-100"
                  accept="image/*"
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={uploadPicture}
                />
              </div>
              {image && cropperModal && (
                <CropperModal
                  image={image}
                  show={cropperModal}
                  handleClose={() => {
                    setCropperModal(false);
                    setImage([]);
                    setImgVal(null);
                  }}
                  handleSubmit={handleOnImageSelect}
                />
              )}
              {formValue.file ? (
                <img src={imgVal} height="auto" width="400px" />
              ) : (
                <img src={formValue.thumbnail} height="auto" width="400px" />
              )}
            </div>
            <TextInput style={inputWidth} name="title" label="Title" />
            <TextInput style={inputWidth} name="excerpt" label="Excerpt" />
            <label className="d-block mb-1">Content</label>
            <TextEditorPage
              editorRef={editorRef}
              setFormValue={setFormValue}
              fromValue={formValue}
              initialContent={formValue.content}
            />
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading && <div className="loader-dark mr-2"></div>}
                Submit
              </Button>
              <Button
                appearance="default"
                onClick={() => navigate(-1)}
                disabled={isLoading}
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </Form>
        </div>
      )}
    </div>
  );
};

export default EditNewsEvent;
