import React, { useEffect } from "react";
import "quill/dist/quill.snow.css";
import { ImageUploadUtil } from "../services/util.service";
import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css";
import { handleErrorBlock } from "../utils/errorBlock";
import { useNavigate } from "react-router-dom";
import uploadToCloudinary from "../utils/uploadToCloudinary";

const TextEditorPage = ({ initialContent, setFormValue }) => {
  const { quill, quillRef } = useQuill();
  const navigate = useNavigate();
  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(initialContent);
      quill.getModule("toolbar").addHandler("image", selectLocalImage);

      quill.on("text-change", (delta, oldDelta, source) => {
        setFormValue((f) => ({ ...f, content: quill.root.innerHTML }));
      });
    }
  }, [quill]);

  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
  };

  const saveToServer = async (file) => {
    try {
      const { url } = await uploadToCloudinary(file);
      console.log("Uploaded Image URL: Response", url);
      insertToEditor(url);
    } catch (e) {
      handleErrorBlock(e, navigate);
    }
  };

  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  return (
    <div style={{ width: 1024, height: 400 }}>
      <div ref={quillRef} />
    </div>
  );
};

export default TextEditorPage;
