import React, { useEffect, useState } from "react";
import CommentItem from "../components/CommentItem";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getAllBlogs, getBlogComments } from "../services/blogs.service";
import { handleErrorBlock } from "../utils/errorBlock";
import { getEventComments } from "../services/events.service";
import Spinner from "../components/Spinner";

const CommentsPage = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  let [searchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const type = searchParams.get("type");
  useEffect(() => {
    (async () => {
      try {
        const res =
          type === "blog"
            ? await getBlogComments(id, page, count)
            : await getEventComments(id, page, count);
        setData(res.data.data);
        console.log(res.data.data);
      } catch (e) {
        handleErrorBlock(e, navigate);
      }
    })();
    setLoading(false);
  }, [update]);

  return (
    <>
      {loading && <Spinner />}
      <div className="p-4 ">
        <h1>{searchParams.get("uid")}</h1>

        {data.length === 0 && !loading ? (
          <div className="d-flex justify-content-center text-center">
            <h1 className="my-5 display-2">Nothing to show here!</h1>
          </div>
        ) : (
          data?.map((item) => <CommentItem key={id} data={item} blogid={id} update={() => setUpdate(!update)} />)
        )}
      </div>
    </>
  );
};

export default CommentsPage;
