import React, { useState } from "react";
import { Button } from "rsuite";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteEvent,
  updateBlogStage,
  updateEventStage,
} from "../services/events.service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { MdDeleteOutline } from "react-icons/md";

const EventsItem = ({ data, type, setUpdate }) => {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  let { userId } = useParams();

  const requestApproval = async () => {
    const res =
      type === "event"
        ? await updateEventStage(data._id, "Unapproved")
        : await updateBlogStage(data._id, "Unapproved");
    if (res.data.status == 200) {
      toast.success("Approval Request Success, kindly refresh!");
      setUpdate((prev) => !prev);
    } else {
      toast.error("error while submitting for approval");
    }
  };

  const grantApproval = async () => {
    const res =
      type === "event"
        ? await updateEventStage(data._id, "Published")
        : await updateBlogStage(data._id, "Published");
    if (res.data.status == 200) {
      toast.success("Approval Request Success, kindly refresh!");
      setUpdate((prev) => !prev);
    } else {
      toast.error("error while submitting for approval");
    }
  };

  const showComments = () => {};

  const profileDetails = useSelector(({ app }) => app.profileDetails);

  const handleDeleteEvent = async () => {
    setIsDeleting(true);
    const res = await deleteEvent(data._id);
    if (res.data.status == 200) {
      toast.success("Event Deleted Successfully, Kindly Refresh!");
    } else {
      toast.error("error while deleting event");
    }
    setUpdate((prev) => !prev);
    setIsDeleting(false);
  };

  const unapproveItem = async () => {
    const res = await updateEventStage(data._id, "Draft");
    if (res.data.status == 200) {
      toast.success("Back to draft Success, kindly refresh!");
      setUpdate((prev) => !prev);
    } else {
      toast.error("error while unapproving item");
    }
  };

  return (
    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center border p-2 my-2 rounded">
      <h4>{data.uniqueID}</h4>
      <p className="py-2 py-md-0">{data.title}</p>
      <div>
        {data.stage == "Draft" && (
          <>
            <Button
              appearance="link"
              color="yellow"
              onClick={() =>
                navigate(
                  type === "event"
                    ? `/news-event-edit/${data._id}`
                    : `/blog-edit/${data._id}`
                )
              }
            >
              Edit
            </Button>
            <Button appearance="link" color="yellow" onClick={requestApproval}>
              Request Approval
            </Button>
          </>
        )}
        {data.stage == "Published" && (
          <>
            <Button
              appearance="link"
              color="green"
              onClick={() =>
                navigate(
                  `/comments/${data._id}?uid=${data.uniqueID}&total=${data.comments?.length}&type=${type}`
                )
              }
            >
              Show Comments
            </Button>
          </>
        )}
        {data.stage == "Unapproved" && profileDetails.type == "Super Admin" && (
          <>
            <Button appearance="link" color="blue" onClick={grantApproval}>
              Approve
            </Button>
          </>
        )}
        {type === "event" &&
          (data.stage == "Unapproved" || data.stage == "Published") &&
          profileDetails.type == "Super Admin" && (
            <>
              <Button appearance="link" color="blue" onClick={unapproveItem}>
                {data.stage == "Published" ? "Unpublish" : "Unapprove"}
              </Button>
            </>
          )}
        <Button
          appearance="ghost"
          color={
            (data.stage === "Draft" && "yellow") ||
            (data.stage === "Published" && "green") ||
            (data.stage === "Unapproved" && "blue")
          }
        >
          {data.stage}
        </Button>
        {type === "event" && (
          <Button
            appearance="ghost"
            color={"red"}
            style={{ marginLeft: 10, fontSize: "20px" }}
            onClick={handleDeleteEvent}
            disabled={isDeleting}
          >
            {isDeleting ? <div className="loader"></div> : <MdDeleteOutline />}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EventsItem;
