import React, { useState, useEffect } from "react";
import UserItem from "./UserItem";
import { useNavigate } from "react-router-dom";
import { GetSalesUserList, GetSocialUserList } from "../services/users.service";
import ReactPaginate from "react-paginate";
import {handleErrorBlock} from "../utils/errorBlock";
import Spinner from "./Spinner";

const UsersListPanel = ({ type }) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        const res =
          type === "sales"
            ? await GetSalesUserList(page, count)
            : await GetSocialUserList(page, count);
        setData(res?.data?.data);
        setPage(res?.data?.meta?.page);
        setTotalPages(res?.data?.meta?.pagesCount);
      } catch (e) {
       handleErrorBlock(e, navigate)
      }
    })();
    setLoading(false);
  }, [page,count,update]);

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  return (
      <>
        {loading && <Spinner />}
    <div>
      {(data.length === 0 && !loading) ? <div className="d-flex justify-content-center text-center" >
        <h1 className="my-5 display-2" >Nothing to show here!</h1>
      </div>:data.map((item) => (
        <UserItem
          key={item._id}
          data={item}
          update={() => setUpdate(!update)}
        />
      ))}
      <div className="pt-3">
        <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={totalPages}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
        />
      </div>
    </div>
        </>
  );
};

export default UsersListPanel;
