import React, { useState } from "react";
import {Button, Modal} from "rsuite";
import {toast} from "react-toastify";
import {handleErrorBlock} from "../utils/errorBlock";
import {useNavigate} from "react-router-dom";
import {DeleteUser} from "../services/users.service";

const UserItem = ({ data,update }) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const navigate = useNavigate();

  const handleDeleteUser = async()  => {
      try {
          const res = await DeleteUser(data._id);
          toast.success("deletion Success");
          setDeleteDialog(false);
          update()
      } catch (e) {
          handleErrorBlock(e, navigate);
      }
  }
  return (
    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center border p-3 rounded mb-3">

      <div className="d-flex justify-content-center align-items-center">
        <img
          src="https://via.placeholder.com/300.png/09f/fff"
          style={{ height: 32 }}
        />
        <h5 className="ms-3">{data.name}</h5>
      </div>
      <p>{data.emailAddress}</p>
      <p>{data.phoneNumber}</p>
      <div style={{ minWidth: 240 }} className="d-flex justify-content-center" >
        <Button
          appearance="link"
          className="text-danger"
          onClick={() => setDeleteDialog(true)}
        >
          Delete
        </Button>
      </div>
        <Modal
            backdrop="static"
            role="alertdialog"
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            size="xs"
        >
            <Modal.Body>Are you sure, you want to delete the faculty ?</Modal.Body>
            <Modal.Footer>
                <Button onClick={handleDeleteUser} appearance="primary">
                    Ok
                </Button>
                <Button onClick={() => setDeleteDialog(false)} appearance="subtle">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
  );
};

export default UserItem;
