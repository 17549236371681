import {
  SET_USER_PROFILE_DATA,
  UPDATE_USER_PREFERENCE,
  CLEAR_REDUX_INITIAL,
} from "./ActionTypes";

export const setUserProfileData = (profileData) => {
  return {
    type: SET_USER_PROFILE_DATA,
    payload: profileData,
  };
};

export const updateUserPreference = (updatedUserData) => {
  return {
    type: UPDATE_USER_PREFERENCE,
    payload: updatedUserData,
  };
};

export const clearReduxInitial = () => {
  return {
    type: CLEAR_REDUX_INITIAL,
    payload: "",
  };
};
