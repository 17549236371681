import React, { useState } from "react";
import {Button, Drawer, Modal} from "rsuite";
import { DeleteFaculty } from "../services/faculty.service";
import { handleErrorBlock } from "../utils/errorBlock";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const FacultyItem = (props) => {
  const navigate = useNavigate();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDeleteFaculty = async () => {
    console.log('delete trigger')
    try {
      const res = await DeleteFaculty(props.data._id);
      console.log(res.data);
      toast.success("deletion Success");
      setDeleteDialog(false);
      props.data.update();
    } catch (e) {
      console.log(e, navigate);
    }
  };

  return (
    <>
      <Drawer size="xs" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Drawer.Body>
          <h3 className="my-2" >Name: {props.data.name}</h3>
          <div>
            <img height="200px" src={props.data.photo} alt=""/>
          </div>
          <h4 className="my-3" >Achievements: {props.data.achievements}</h4>
          <h4 className="my-1" >Bio: {props.data.bio}</h4>
        </Drawer.Body>
      </Drawer>
      <div className="d-flex justify-content-between align-items-center border p-3 rounded">
        <div className="d-flex justify-content-center align-items-center">
          <img src={props.data.photo} style={{ height: 32 }} />
          <h5 className="ms-3">{props.data.name}</h5>
        </div>
        <div>
          <Button appearance="link" onClick={() => setIsDrawerOpen(true)} >View</Button>
          <Button
            appearance="link"
            onClick={() => navigate(`/faculty-edit/${props.data._id}`)}
          >
            Edit
          </Button>
          <Button
            appearance="link"
            className="text-danger"
            onClick={() => setDeleteDialog(true)}
          >
            Delete
          </Button>
        </div>
        <Modal
          backdrop="static"
          role="alertdialog"
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          size="xs"
        >
          <Modal.Body>
            Are you sure, you want to delete the faculty ?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleDeleteFaculty} appearance="primary">
              Ok
            </Button>
            <Button onClick={() => setDeleteDialog(false)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default FacultyItem;
