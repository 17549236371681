import React, { useEffect, useState } from "react";
import { Button, Loader, SelectPicker } from "rsuite";
import EventsItem from "../components/EventsItem";
import { useNavigate } from "react-router-dom";
import { handleErrorBlock } from "../utils/errorBlock";
import { getAllBlogs } from "../services/blogs.service";
import { filterOptions } from "../Constants/otherConstants";
import ReactPaginate from "react-paginate";
import Spinner from "../components/Spinner";

const BlogsList = () => {
  const [type, setType] = useState("All");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const res = await getAllBlogs(type, page, count);
        setData(res.data.data);
        setPage(res?.data?.meta?.page);
        setTotalPages(res?.data?.meta?.pagesCount);
      } catch (e) {
        handleErrorBlock(e, navigate);
      }
    })();
    setLoading(false);
  }, [page, count, type, update]);

  const handlePageClick = (e) => {
    console.log(e);
    setPage(e.selected + 1);
    setUpdate(!update);
  };
  return (
    <>
      {loading && <Spinner />}
      <div className="p-4">
        <div>
          <div className="d-flex justify-content-between align-items-center ">
            <h1 className="display-4" >Blogs</h1>
            <div>
              <Button
                appearance="primary"
                onClick={() => navigate("/blog-add")}
              >
                Add Blog
              </Button>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-end align-items-center gap-2 my-3">
              <label htmlFor="">Filter By</label>
              <SelectPicker
                defaultValue="All"
                onChange={(e) => setType(e)}
                data={filterOptions}
                searchable={false}
                style={{ width: 224 }}
              />
            </div>
          </div>
        </div>
        <div>
          {(data.length === 0 && !loading) ? (
            <div className="d-flex justify-content-center text-center">
              <h1 className="my-5 display-2">Nothing to show here!</h1>
            </div>
          ) : (
            data.map((item, idx) => (
              <EventsItem key={idx} data={item} type="blog" />
            ))
          )}
        </div>
        <div>
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={totalPages}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </>
  );
};

export default BlogsList;
