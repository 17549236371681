import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import {IoClose} from 'react-icons/io5';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const profileDetails = useSelector(({ app }) => app.profileDetails);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <nav className="">
      <div className="d-flex justify-content-between align-items-center p-2 ">
        <h3>TAG-Admin</h3>
        <div onClick={() => setOpen(!open)}>
          {!open ? (
            <GiHamburgerMenu size="26" />
          ) : (
            <IoClose size="26" color={"white"} />
          )}
        </div>
      </div>
      {open ? (
        <div>
          <div>
            {profileDetails.type === "Super Admin" && (
              <>
                <div onClick={() => {
                  navigate("/");
                  setOpen(false);
                }} className="p-2">
                  Faculty
                </div>
                <div onClick={() => {
                  navigate("/users");
                  setOpen(false);
                }} className="p-2">
                  Users
                </div>
                <div onClick={() => {
                  navigate("/queries");
                  setOpen(false);
                }} className="p-2">
                  Queries
                </div>
                <div onClick={() => {
                  navigate("/events-list");
                  setOpen(false);
                }} className="p-2">
                  Events/News
                </div>
                <div onClick={() => {
                  navigate("/blogs-list");
                  setOpen(false);
                }} className="p-2">
                  Blogs
                </div>
              </>
            )}
            {profileDetails.type === "Sales" && (
              <>
                <div onClick={() => {
                  navigate("/queries");
                  setOpen(false);
                }} className="p-2">
                  Queries
                </div>
              </>
            )}
            {profileDetails.type === "Social" && (
              <>
                <div onClick={() => {
                  navigate("/events-list");
                  setOpen(false);
                }} className="p-2">
                  Events/News
                </div>
                <div onClick={() => {
                  navigate("/blogs-list");
                  setOpen(false);
                }} className="p-2">
                  Blogs
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </nav>
  );
};

export default Navbar;
