import axios from "axios";
import { Endpoints } from "../Constants/Endpoints";
import { fetchJWTToken } from "../utils/authUtils";

const token = fetchJWTToken();

export function GetMentorList(page, count) {
  return axios({
    method: "GET",
    url: `${Endpoints.MENTOR_LIST}&page=${page}&count=${count}`,
    headers: {
      Authorization: token,
    },
  });
}

export function GetWPLList(page, count) {
  return axios({
    method: "GET",
    url: `${Endpoints.WPL_LIST}&page=${page}&count=${count}`,
    headers: {
      Authorization: token,
    },
  });
}

export function CreateFaculty(data) {
  return axios({
    method: "POST",
    url: Endpoints.FACULTY_URL,
    data: data,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json"
    },
  });
}

export function EditFacultyService(data,id) {
  return axios({
    method: "PUT",
    url: `${Endpoints.FACULTY_URL}/${id}`,
    data: data,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json"
    },
  });
}


export function EditFacultyServiceNonImage(data,id) {
  return axios({
    method: "PUT",
    url: `${Endpoints.FACULTY_URL}/non-image/${id}`,
    data: data,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json"
    },
  });
}

export function DeleteFaculty(id) {
  return axios({
    method: "DELETE",
    url: `${Endpoints.FACULTY_URL}/${id}`,
    headers: {
      "X-access-Token": token,
      "content-type": "application/json"
    },
  });
}

export function GetFacultyDetails(id) {
  return axios({
    method: "GET",
    url: `${Endpoints.FACULTY_URL}/${id}`,
    headers: {
      Authorization: token,
    },
  });
}

