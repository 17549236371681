import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Form,
  Input,
  Schema,
  SelectPicker,
} from "rsuite";
import TextInput from "../components/TextInput";
import { CreateFaculty } from "../services/faculty.service";
import { handleErrorBlock } from "../utils/errorBlock";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const TypeData = [
  {
    label: "Mentor",
    value: "Mentor",
  },
  {
    label: "WPL",
    value: "WPL",
  },
];

const inputWidth = {
  width: "clamp(300px, 40vw, 500px)",
};

const AddFaculty = () => {
  const [formValue, setFormValue] = React.useState({
    type: "",
    name: "",
    designation:"",
    achievements: "",
    bio: "",
    file: "",
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const model = Schema.Model({
    name: Schema.Types.StringType().isRequired("Name Cannot be empty"),
    achievements: Schema.Types.StringType().isRequired("This Field Required"),
    designation: Schema.Types.StringType().isRequired("This Field Required"),
    bio: Schema.Types.StringType().isRequired("This Field is required."),
    type: Schema.Types.StringType()
      .minLength(3)
      .isRequired("This field is required"),
  });

  const navigate = useNavigate();

  const uploadPicture = (e) => {
    setFormValue({ ...formValue, file: e.target.files[0] });
  };

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      toast.error("Please Check all the fields");
    } else {
      const data = new FormData();
      for (let item in formValue) {
        data.append(`${item}`, formValue[item]);
      }
      try {
        let res = await CreateFaculty(data);
        console.log(res);
        toast.success("Faculty created successfully");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } catch (e) {
        handleErrorBlock(e, navigate);
      }
    }
  };

  return (
    <div className="p-4">
      <h2 className="display-4">Add Faculty</h2>

      <Form
        ref={formRef}
        fluid
        onChange={setFormValue}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        className="mt-3 "
        onSubmit={handleSubmit}
      >
        <div className="d-flex flex-column mb-3">
          <label htmlFor="">Photo</label>
          <input type="file" onChange={uploadPicture} />
          {formValue?.file && (
            <img
              src={URL.createObjectURL(formValue.file) || ""}
              height="200px"
              width="200px"
            />
          )}
        </div>
        <label htmlFor="" className="d-block mb-1">
          Select Type
        </label>
        <SelectPicker
          name="type"
          data={TypeData}
          searchable={false}
          style={{ width: 224, marginBottom: 10 }}
          onSelect={(e) => setFormValue({ ...formValue, type: e })}
        />
        <TextInput
          style={inputWidth}
          name="name"
          label="Name"
          onChange={(e) => setFormValue({ ...formValue, name: e })}
        />
        <TextInput
          style={inputWidth}
          name="designation"
          label="Designation"
          onChange={(e) => setFormValue({ ...formValue, designation: e })}
        /><TextInput
          style={inputWidth}
          name="achievements"
          label="Achievements"
          onChange={(e) => setFormValue({ ...formValue, achievements: e })}
        />
        <label className="d-block mb-1">Bio</label>
        <Input
          className="mb-3"
          as="textarea"
          rows={3}
          placeholder="Bio"
          onChange={(e) => setFormValue({ ...formValue, bio: e })}
        />
        <ButtonToolbar>
          <Button appearance="primary" type="submit">
            Submit
          </Button>
          <Button appearance="default" onClick={()=> navigate(-1)} >Cancel</Button>
        </ButtonToolbar>
      </Form>
    </div>
  );
};

export default AddFaculty;
