import React from 'react';
import {Button} from "rsuite";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import UsersListPanel from "../components/UsersListPanel";
import {useNavigate} from "react-router-dom";

const UsersPage = () => {

    const navigate = useNavigate();

    return (
        <div className="p-4">
            <div className="d-flex justify-content-between align-items-center mb-3 ">
                <h2 className="display-4">Users</h2>
                <Button onClick={() => navigate('/users-add')} >Add User</Button>
            </div>
            <div className="mt-3" >
                <Tabs>
                    <TabList>
                        <Tab>Sales</Tab>
                        <Tab>Social</Tab>
                    </TabList>
                    <TabPanel>
                        <UsersListPanel type="sales" />
                    </TabPanel>
                    <TabPanel>
                        <UsersListPanel type="social" />
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
};

export default UsersPage;
