import React, { useEffect, useState } from "react";
import {
    Button,
    ButtonToolbar,
    Form,
    Input,
    Schema,
    SelectPicker,
} from "rsuite";
import TextInput from "../components/TextInput";
import { EditFacultyService, EditFacultyServiceNonImage, GetFacultyDetails} from "../services/faculty.service";
import { handleErrorBlock } from "../utils/errorBlock";
import {useNavigate, useParams} from "react-router-dom";
import { toast } from "react-toastify";

const TypeData = [
    {
        label: "Mentor",
        value: "Mentor",
    },
    {
        label: "WPL",
        value: "WPL",
    },
];

const inputWidth = {
    width: "clamp(300px, 40vw, 500px)",
};

const EditFaculty = () => {
    const [formValue, setFormValue] = React.useState({
        type: "",
        name: "",
        achievements: "",
        bio: "",
        file: "",
    });
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    let { id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        (async() => {
          try{
              const res = await GetFacultyDetails(id);
              const data = res?.data?.data;
              setFormValue({
                  type:data.type,
                  name: data.name,
                  achievements:data.achievements,
                  bio: data.bio,
                  photo: data.photo,
              })
          }catch (e) {
              handleErrorBlock(e, navigate);

          }
        })()
    }, []);

    const model = Schema.Model({
        name: Schema.Types.StringType().isRequired("Name Cannot be empty"),
        achievements: Schema.Types.StringType().isRequired("This Field Required"),
        bio: Schema.Types.StringType().isRequired("This Field is required."),
        type: Schema.Types.StringType()
            .minLength(3)
            .isRequired("This field is required"),
    });

    const uploadPicture = (e) => {
        setFormValue({ ...formValue, file: e.target.files[0] });
    };

    const handleSubmit = async () => {
        // if (!formRef.current.check()) {
        //     toast.error("Please Check all the fields");
        //     return;
        // }
        if (!formValue?.bio && !formValue.achievements && !formValue.file && !formValue?.name && !formValue?.type) {
            toast.error("Please Check all the fields");
            return;
        }
       
        
        if(!formValue?.file) {
            // TODO -> Call API which processes faculty editing without photo
            let body = {}
            if(formValue?.bio) { body['bio'] = formValue?.bio }
            if(formValue?.achievements) { body['achievements'] = formValue?.achievements }
            if(formValue?.name) { body['name'] = formValue?.name }
            if(formValue?.type) { body['type'] = formValue?.type }

            try {
                let res = await EditFacultyServiceNonImage(body, id);
                console.log(res);
                toast.success("Faculty updated successfully (non-image)");
                setTimeout(() => {
                    navigate("/");
                }, 1000);
            } catch (e) {
                handleErrorBlock(e, navigate);
            }
        } else {
            // Photo updated / uploaded new one
            const data = new FormData();
            for (let item in formValue) {
                console.log('item:', item)
                data.append(`${item}`, formValue[item]);
            }
            data.delete("photo")
            try {
                let res = await EditFacultyService(data,id);
                console.log(res);
                toast.success("Faculty updated successfully");
                setTimeout(() => {
                    navigate("/");
                }, 1000);
            } catch (e) {
                handleErrorBlock(e, navigate);
            }
        }
    };

    return (
        <div className="p-4">
            <h2>Edit Faculty</h2>

            <Form
                ref={formRef}
                fluid
                onChange={setFormValue}
                onCheck={setFormError}
                formValue={formValue}
                model={model}
                className="mt-3 "
                onSubmit={handleSubmit}
            >
                <div className="d-flex flex-column mb-3">
                    <label htmlFor="">Photo</label>
                    <input type="file" onChange={uploadPicture} />
                    {formValue?.file ? (
                        <img
                            src={URL.createObjectURL(formValue.file) || formValue.photo}
                            height="200px"
                            width="200px"
                        />
                    ):<img
                        src={formValue.photo}
                        height="200px"
                        width="200px"
                    />}

                </div>
                <label htmlFor="" className="d-block mb-1">
                    Select Type
                </label>
                <SelectPicker
                    value={formValue.type}
                    name="type"
                    data={TypeData}
                    searchable={false}
                    style={{ width: 224, marginBottom: 10 }}

                    onSelect={(e) => setFormValue({ ...formValue, type: e })}
                />
                <TextInput
                    style={inputWidth}
                    name="name"
                    label="Name"
                    onChange={(e) => setFormValue({ ...formValue, name: e })}
                />
                <TextInput
                    style={inputWidth}
                    name="achievements"
                    label="Achievements"
                    onChange={(e) => setFormValue({ ...formValue, achievements: e })}
                />
                <label className="d-block mb-1">Bio</label>
                <Input
                    className="mb-3"
                    as="textarea"
                    name="bio"
                    value={formValue.bio}
                    rows={3}
                    placeholder="Bio"
                    onChange={(e) => setFormValue({ ...formValue, bio: e })}
                />
                <ButtonToolbar>
                    <Button appearance="primary" type="submit">
                        Submit
                    </Button>
                    <Button appearance="default" onClick={()=> navigate(-1)} >Cancel</Button>
                </ButtonToolbar>
            </Form>
        </div>
    );
};

export default EditFaculty;
